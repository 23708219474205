export const capitalizeNames = (fullName) => {
  const names = fullName.split(" ");
  const capitalizedNames = names.map(
    (name) => name.charAt(0).toUpperCase() + name.slice(1)
  );
  return capitalizedNames.join(" ");
};

export const checkConditionForSocialPopup = (payLoad) => {
  if (!payLoad.event.socialMedia) return false;

  const { facebookUrl, instagramUrl, tiktokUrl, youtubeUrl, whatsAppUrl } =
    payLoad.event.socialMedia;

  // Check if each URL is truthy (not null, undefined, or empty string)
  return [facebookUrl, instagramUrl, tiktokUrl, youtubeUrl, whatsAppUrl].some(
    (url) => Boolean(url)
  );
};

export function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    // timeZone: "UTC",
  };
  return date.toLocaleString("en-US", options);
}
