import UserInfo from "../../components/user-info/user-info";
import "./landing-route.style.scss";

const LandingRoute = ({ decodedUrlObject }) => {
  const eventId = decodedUrlObject.eventId;
  const promotorId = decodedUrlObject.promotorId;
  const doormanId = decodedUrlObject.doormanId;
  const type = decodedUrlObject.type;
  const uniqueHash = decodedUrlObject.uniqueHash;

  return (
    <div className="landing-route">
      <UserInfo
        eventId={eventId}
        promotorId={promotorId}
        doormanId={doormanId}
        type={type}
        uniqueHash={uniqueHash}
      />
    </div>
  );
};

export default LandingRoute;
