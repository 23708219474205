import { Fragment } from "react";
import { ScaleLoader } from "react-spinners";

const LoadingSpinner = () => {
  return (
    <Fragment>
      <ScaleLoader color="#4b0082" />
    </Fragment>
  );
};

export default LoadingSpinner;
