export const USER_ROLES = {
  ADMIN: "ADMIN",
  PROMOTOR: "PROMOTOR",
  DOOR_TEAM: "DOOR_TEAM",
  SUB_PROMOTOR: "SUB_PROMOTOR",
  BARTENDER: "BARTENDER",
  COCKTAIL_WAITRESS: "COCKTAIL_WAITRESS",
  DJ: "DJ",
  SECURITY: "SECURITY",
  VIP_HOST: "VIP_HOST",
  TEMP_PROMOTOR: "TEMP_PROMOTOR",
  CASHIER: "CASHIER",
};
