const ErrorMessage = () => {
  return (
    <p>
      Something went wrong while fetching the details. Please try again by
      scanning the QR
    </p>
  );
};

export default ErrorMessage;
