import axios from "axios";
import { BASE_URL } from "../utils/consts";

const updateGuestNameAPI = async (eventId, inviteId, uniqueHash, guestName) => {
  const url = `${BASE_URL}/invitedVia/expressEntry/updateGuestName`;

  const responseObject = {
    eventId: eventId,
    inviteId: inviteId,
    uniqueHash: uniqueHash,
    guestName: guestName,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      url,
      responseObject,

      {
        headers: headers,
      }
    );

    const data = await response.data;

    return data;
  } catch (error) {
    throw error;
  }
};

export default updateGuestNameAPI;
