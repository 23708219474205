import { useState, useEffect } from "react";
import { userInfoAPI } from "../../apis/user-info";

import { changeQRColor } from "../../utils/QR/get-QRcolor";
import { USER_ROLES } from "../../utils/user-roles/user-roles";
import { capitalizeNames, convertTimestamp } from "../../utils/helpers";
import { QRTypes } from "../../utils/QR/get-QRcolor";

import QRCode from "qrcode.react";

import ErrorMessage from "../error-message/error-message";

import LoadingSpinner from "../loading-indicator/loading-indicator.component";
import updateGuestNameAPI from "../../apis/update-guest";

import FormInput from "../form-input/form-input.component";
import SocialMediaLinks from "../links-footer/links-footer";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import PopupGlobal from "../popup/popup";
import SocialPopupChildComponent from "../popup/child-components/social-media/social-media";

import { checkConditionForSocialPopup } from "../../utils/helpers";

import "./user-info.style.scss";
import "../card/card.style.scss";

const UserInfo = ({ eventId, promotorId, doormanId, type, uniqueHash }) => {
  const [payLoad, setPayLoad] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopUp] = useState(false);
  const [formFields, setFormFields] = useState({ fullNamePopUp: "" });
  const [showGlobalPopUp, setShowGlobalPopUp] = useState(true);

  const { fullNamePopUp } = formFields;

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await userInfoAPI({
          eventId: eventId,
          promotorId: promotorId,
          uniqueHash: uniqueHash,
        });

        setPayLoad(data.payLoad);
        setIsLoading(false);

        conditionsCheckForQuickInvite(data);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserInfo();
  }, [eventId, promotorId, uniqueHash]);

  const conditionsCheckForQuickInvite = (data) => {
    // console.log(payLoad, "beginning of quick invte check");
    if (data.payLoad.isGuestInfoUpdated === false) {
      setShowPopUp(true);
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    const updatedFormFields = { ...formFields, [name]: value };
    setFormFields(updatedFormFields);
    // dispatch(setFormFields(updatedFormFields));
  };

  const QRdata = JSON.stringify({
    eventId: eventId,
    promotorId: promotorId,
    code: payLoad.code,
    doormanId: doormanId,
    type: type,
    uniqueHash: uniqueHash,
    imageUrl: payLoad.imageUrl,
    invitedBy: payLoad.member?.fullName,
    inviteId: payLoad.id,
  });

  const QRcolor = changeQRColor(type, payLoad.member?.email);

  // console.log(QRdata);

  if (isLoading)
    return (
      <div className="profile-card">
        <LoadingSpinner />
      </div>
    );

  if (!type || !doormanId || !uniqueHash || !payLoad.event)
    return (
      <div className="profile-card">
        <ErrorMessage />
      </div>
    );

  const CustomPopUp = () => {
    const handleSubmit = async (e) => {
      e.preventDefault();
      // console.log(payLoad, "on beiginng of submitting of popup");

      if (!formFields.fullNamePopUp.trim()) {
        alert("Full Name cannot be empty!");
        return;
      } else {
        try {
          setShowPopUp(false);

          const updateGuestName = await updateGuestNameAPI(
            eventId,
            payLoad.id,
            uniqueHash,
            fullNamePopUp
          );

          setPayLoad(updateGuestName.payLoad);
        } catch (e) {
          console.error(e);
        }
      }
    };

    return (
      <Popup
        open={showPopup}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        overlayStyle={{
          transitionDelay: "1000",
          background: "rgba(0, 0, 0, 0.5)",
        }}
        contentStyle={{
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          background: "rgb(255, 255, 255)",
          width: "300px",
          height: "200px",
          padding: "20px",
        }}
        arrowStyle={{
          color: "rgb(255, 255, 255)",
        }}
      >
        <form className="form-popup" onSubmit={handleSubmit}>
          <h2 className="custom-popup-heading"> Please enter you name </h2>
          <FormInput
            label="Full Name"
            type="text"
            placeholder="Full Name"
            name="fullNamePopUp"
            value={fullNamePopUp}
            onChange={onChangeHandler}
            required
          />
          <button className="sign-in-button" onClick={handleSubmit}>
            Submit
          </button>
        </form>
      </Popup>
    );
  };

  return (
    // <div className="user-info-card">
    <div className="profile-card">
      <div className="event-information">
        <p className="event-name">{payLoad.event.eventName}</p>
        <p className="event-date">
          {convertTimestamp(payLoad.event.startTime)}
        </p>
        <p className="event-location">{payLoad.event.location}</p>
      </div>

      <div className="profile-image">
        <img src={payLoad.event.imageUrl} alt="Profile" />
      </div>

      <div className="profile-info">
        {payLoad.fullName && (
          <h2 className="user-name">{capitalizeNames(payLoad.fullName)}</h2>
        )}
        <h2 className="invited-by">Invited by</h2>
        <p className="invited-by-name">
          {payLoad.member.userType === USER_ROLES.ADMIN
            ? "Event Owner"
            : capitalizeNames(payLoad.member.fullName)}
        </p>
      </div>

      {payLoad.type === QRTypes.comps || payLoad.type === QRTypes.COMPS ? (
        <p className="QR-optional-text">
          Scan your QR code for a complementary ticket
        </p>
      ) : (
        <p className="QR-optional-text">
          Scan your QR code for a reduced ticket
        </p>
      )}

      <div className="QR-content">
        {/*payLoad.type === QRTypes.REDUCE || payLoad.type === QRTypes.reduce ? (
        //   <div className="QR-reduce-type-text-container">
        //     <p className="QR-reduce-type-text"> Reduce Ticket</p>
        //   </div>
        // ) : (
        //   ""
        // )}

        // {payLoad.type === QRTypes.COMPS || payLoad.type === QRTypes.comps ? (
        //   <div className="QR-reduce-type-text-container">
        //     <p className="QR-comps-type-text"> Comp Ticket</p>
        //   </div>
        // ) : (
        //   ""
        // )}

        {/*<p className="message-text">*/}
        {/*  {payLoad.isVerified*/}
        {/*    ? "Your check-in was successfully verified"*/}
        {/*    : "Present this barcode at the door for expedited entry"}*/}
        {/*</p>*/}

        <div className="QR-code-container">
          <QRCode value={QRdata} fgColor={QRcolor} />

          <p className="code"> code: {payLoad.code}</p>
        </div>
      </div>

      {/*<div className="text-container">*/}
      {/*  <p className="venue-text">*/}
      {/*    <strong>Venue:</strong> {payLoad.event.location}*/}
      {/*  </p>*/}
      {/*</div>*/}

      {showPopup && <CustomPopUp />}
      {showGlobalPopUp &&
        !showPopup &&
        checkConditionForSocialPopup(payLoad) && (
          <PopupGlobal
            setShowGlobalPopup={setShowGlobalPopUp}
            showGlobalPopup={showGlobalPopUp}
            ChildComponent={() => (
              <SocialPopupChildComponent
                instagramLink={payLoad.event.socialMedia.instagramUrl}
                facebookLink={payLoad.event.socialMedia.facebookUrl}
                youtubeLink={payLoad.event.socialMedia.youtubeUrl}
                tiktokLink={payLoad.event.socialMedia.tiktokUrl}
                whatsAppLink={payLoad.event.socialMedia.whatsAppUrl}
              />
            )}
            popupHeading="Follow us on"
          />
        )}
    </div>
    //   <SocialMediaLinks
    //     instagramLink={payLoad.event.socialMedia.instagramUrl}
    //     facebookLink={payLoad.event.socialMedia.facebookUrl}
    //     youtubeLink={payLoad.event.socialMedia.youtubeUrl}
    //     tiktokLink={payLoad.event.socialMedia.tiktokUrl}
    //   />
    // </div>
  );
};

export default UserInfo;

// <h2 className="user-name">{payLoad.member.fullName}</h2>;
